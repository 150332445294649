export const ROUTE_CONSTANTS = {
  LAYOUT: '/',
  LOGIN: '/login',
  LOGOUT:'/logout',
  PlANNERWINDOW: '/plannerwindow',
  SELLIN: '/sellin',
  SELLOUT: '/sellout',
  COLLECTIONPLAN: '/collectionplan',
  OPEXPLAN: '/opexplan',
  ANNUALCOLLECTIONPLAN: '/annualcollectionplan',
  ANNUALSALES:'/annualsales',
  ANNUALOPEX: '/annualopexplan',
}
